import * as React from 'react'
import { graphql } from 'gatsby'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import SEO from '../components/seo'
import Layout from '../components/layout'

interface Props {
  data: {
    books: {
      group: Array<{
        fieldValue: string
        field: string
        totalCount: number
        edges: Array<{
          node: {
            title: string
            author: string
            ranking: number
            rating: number
          }
        }>
      }>
    }
  }
}

const percentileMarker = (ranking: number, totalCount: number) => {
  if (ranking <= totalCount * 0.05) {
    return '***'
  }
  if (ranking <= totalCount * 0.1) {
    return '**'
  }
  if (ranking <= totalCount * 0.15) {
    return '*'
  }
}

const BookshelfPage: React.FC<Props> = (props) => {
  const { data } = props
  const total = data.books.group.reduce((a, m) => m.totalCount + a, 0)
  return (
    <Layout>
      <SEO title="Bookshelf" />
      <h1>Bookshelf</h1>
      <p>
        Here is a list of all the books I've read or listened to since 2011.
      </p>
      <p>
        ***: Top 5%
        <br />
        **: Top 10%
        <br />
        *: Top 15%
      </p>
      {reverse(sortBy(data.books.group, 'fieldValue')).map((g) => (
        <div>
          <h2>{g.fieldValue}</h2>
          <div>
            {g.edges.map(({ node }) => (
              <p key={node.title}>
                {percentileMarker(node.ranking, total)} {node.title} by{' '}
                {node.author}
              </p>
            ))}
          </div>
        </div>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query Books {
    books: allGoogleBooksSheet(sort: { fields: startDate__num_, order: DESC }) {
      group(field: endYear) {
        fieldValue
        field
        edges {
          node {
            title
            author
            rating
            ranking
          }
        }
        totalCount
      }
    }
  }
`

export default BookshelfPage
